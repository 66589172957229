import LoadingPageMessage from './LoadingPageMessage'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  TextField,
  Card,
  CardContent,
  Stack
} from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { CenteredDiv, PrimaryButton, SnackbarVariants } from '@wavetronix/common-components'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import DtsReportsApi from '../api/DtsReportsApi'
import { useSnackbar } from 'notistack'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

const DEFAULT_LAYOUT = {
  id: '',
  isCarousel: false,
  carouselPages: [],
  includedCabinets: []
}

const DEFAULT_PAGE = {
  seconds: 10,
  cabinets: []
}
const INITIAL_CAROUSEL = [DEFAULT_PAGE]

export default function ConfigureLayouts({ allLayouts, allLayoutsLoading, reportsData, layoutsRefetch }) {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [cabinetPages, setCabinetPages] = useState(INITIAL_CAROUSEL)
  const [newLayoutName, setNewLayoutName] = useState('')

  useEffect(() => {
    if (selectedLayout && reportsData) {
      //carouselPages is List of Page {seconds: int, cabinets: []cabinetInfo}
      if (selectedLayout.carouselPages.length > 0) {
        setCabinetPages(selectedLayout.carouselPages)
      }
    }
  }, [selectedLayout, reportsData])

  const cabinetOptions = useMemo(() => {
    if (reportsData && cabinetPages) {
      const includeCIds = cabinetPages.map(page => page.cabinets.map(c => c.id)).flat()
      return reportsData.cabinetOverviews.filter(c => !includeCIds.includes(c.id)).map(c => ({ id: c.id, name: c.cabinetName }))
    }
  }, [reportsData, cabinetPages])

  const updateCabinetPage = (destinationPage, value, newValue) => {
    setCabinetPages(prevPages => {
      // Create a copy of the current page with updated cabinets
      const updatedPage = {
        ...prevPages[destinationPage],
        [value]: newValue
      }
      // Return a new array with the updated page
      let newPages = prevPages.map((page, index) => (index === destinationPage ? updatedPage : page))

      return newPages
    })
  }

  const getPageIndexFromDroppableId = droppableId => {
    if (droppableId.includes('layoutPage')) {
      return parseInt(droppableId.replace('layoutPage', ''), 10)
    } else {
      return droppableId
    }
  }

  const onDragEnd = result => {
    const { source, destination } = result

    if (!destination) return

    let sourcePageIndex = getPageIndexFromDroppableId(source.droppableId)
    let destinationPageIndex = getPageIndexFromDroppableId(destination.droppableId)

    // Moving from available to a page
    if (source.droppableId === 'available' && destination.droppableId.includes('layoutPage')) {
      const draggedCabinet = cabinetOptions[source.index]
      const destinationUpdatedCabinets = [...cabinetPages[destinationPageIndex].cabinets]
      destinationUpdatedCabinets.splice(destination.index, 0, draggedCabinet)

      updateCabinetPage(destinationPageIndex, 'cabinets', destinationUpdatedCabinets)
    }
    // Moving from page to available
    else if (destination.droppableId === 'available' && source.droppableId.includes('layoutPage')) {
      const draggedCabinet = cabinetPages[sourcePageIndex].cabinets[source.index]

      updateCabinetPage(
        sourcePageIndex,
        'cabinets',
        cabinetPages[sourcePageIndex].cabinets.filter(c => c.id !== draggedCabinet.id)
      )
    } else if (source.droppableId.includes('layoutPage') && destination.droppableId.includes('layoutPage')) {
      // Moving within the current page
      if (source.droppableId === destination.droppableId && source.index !== destination.index) {
        const updatedCabinets = Array.from(cabinetPages[destinationPageIndex].cabinets)
        const [movedCabinet] = updatedCabinets.splice(source.index, 1)
        updatedCabinets.splice(destination.index, 0, movedCabinet)
        updateCabinetPage(destinationPageIndex, 'cabinets', updatedCabinets)
      }
      // Moving from one page to another
      else if (source.droppableId !== destination.droppableId) {
        const sourceUpdatedCabinets = Array.from(cabinetPages[sourcePageIndex].cabinets)
        const destinationUpdatedCabinets = Array.from(cabinetPages[destinationPageIndex].cabinets)
        const [movedCabinet] = sourceUpdatedCabinets.splice(source.index, 1)
        destinationUpdatedCabinets.splice(destination.index, 0, movedCabinet)
        updateCabinetPage(sourcePageIndex, 'cabinets', sourceUpdatedCabinets)
        updateCabinetPage(destinationPageIndex, 'cabinets', destinationUpdatedCabinets)
      }
    }
  }

  if (allLayoutsLoading) {
    return <LoadingPageMessage message={'Loading all layout configurations. Please wait...'} />
  }

  if (!reportsData) {
    return <LoadingPageMessage message={'Loading reports data. Please wait...'} />
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={3} xl={2}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px' }}>Layouts</h3>
            <IconButton
              id='configureNewLayoutButton'
              sx={{ marginTop: '15px', marginLeft: '15px' }}
              onClick={() => {
                setSelectedLayout(DEFAULT_LAYOUT)
              }}
            >
              <AddIcon />
            </IconButton>
          </CenteredDiv>
        </Grid>
        <Grid item lg={9} xl={10}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px', marginTop: '27px' }}>Change Configuration</h3>
          </CenteredDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <List>
            {allLayouts
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map(layout => (
                <ListItem key={layout.id} disablePadding>
                  <ListItemButton
                    sx={{ borderRadius: '20px', boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.9)', marginBottom: '15px' }}
                  >
                    <ListItemIcon
                      id={`${layout.id}LayoutListItemDeleteButton`}
                      onClick={async () => {
                        if (layout.id !== 'InternalLayout') {
                          await DtsReportsApi.deleteCardLayouts(layout.id).then(async () => await layoutsRefetch())
                          enqueueSnackbar('Successfully delete configuration.', SnackbarVariants.SUCCESS)
                          setSelectedLayout(null)
                        } else {
                          enqueueSnackbar('InternalLayout is the default layout and cannot be deleted.', SnackbarVariants.ERROR)
                        }
                      }}
                    >
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText
                      id={`${layout.id}LayoutListItemSelectButton`}
                      primary={layout.id}
                      onClick={() => {
                        setCabinetPages(INITIAL_CAROUSEL)
                        setSelectedLayout(layout)
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item lg={9} xl={10}>
          {selectedLayout ? (
            <Card>
              <CardContent>
                {selectedLayout.id === '' ? (
                  <TextField
                    id='newLayoutIdTextField'
                    label='Layout Name'
                    size='small'
                    value={newLayoutName}
                    onChange={e => setNewLayoutName(f => e.target.value)}
                  />
                ) : (
                  <Box>
                    <h3>Layout Name: {selectedLayout.id}</h3>
                    <h4>
                      This layout configuration is for monitors greater than 1536px, for smaller views cabinets will be combined
                      on pages according to space availability (left to right, top to bottom).
                    </h4>
                    <PrimaryButton
                      id='saveLayoutButton'
                      onClick={async () => {
                        await DtsReportsApi.setCardLayouts({
                          ...selectedLayout,
                          id: selectedLayout.id === '' ? newLayoutName : selectedLayout.id,
                          carouselPages: cabinetPages
                        })
                        enqueueSnackbar('Saved layout configuration.', SnackbarVariants.SUCCESS)
                      }}
                    >
                      Save
                    </PrimaryButton>
                    <PrimaryButton
                      id='addPageButton'
                      onClick={async () => {
                        setCabinetPages(pages => [...pages, DEFAULT_PAGE])
                      }}
                    >
                      Add Page
                    </PrimaryButton>
                  </Box>
                )}
                {selectedLayout && cabinetOptions && cabinetPages ? (
                  <Box>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                        {/* Available Cabinets */}
                        <Droppable droppableId='available'>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              style={{
                                border: '1px solid lightgray',
                                padding: '16px',
                                width: '200px',
                                minHeight: '300px'
                              }}
                            >
                              <h3>Available Cabinets</h3>
                              {cabinetOptions
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((cabinet, index) => (
                                  <Draggable key={cabinet.id} draggableId={cabinet.id} index={index}>
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          padding: '8px',
                                          margin: '4px 0',
                                          background: 'lightblue',
                                          borderRadius: '4px',
                                          ...provided.draggableProps.style
                                        }}
                                      >
                                        {cabinet.name}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>

                        {cabinetPages.map((page, pageIndex) => (
                          <Stack
                            key={`layoutPage${pageIndex}Stack`}
                            sx={{
                              border: '1px solid lightgray',
                              width: '200px',
                              minHeight: '800px'
                            }}
                          >
                            <TextField
                              value={page.seconds}
                              label='Seconds'
                              size='small'
                              type='number'
                              sx={{ margin: '10px' }}
                              inputProps={{
                                min: 10,
                                max: 60,
                                step: 1
                              }}
                              onChange={e => updateCabinetPage(pageIndex, 'seconds', e.target.value)}
                            />
                            <PrimaryButton
                              id='removePageButton'
                              style={{ margin: '10px' }}
                              onClick={async () => {
                                setCabinetPages(pages => pages.filter((_, i) => i !== pageIndex))
                              }}
                            >
                              Remove Page
                            </PrimaryButton>
                            <Droppable droppableId={`layoutPage${pageIndex}`} key={`layoutPageDroppable${pageIndex}`}>
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  style={{
                                    // border: '1px solid lightgray',
                                    margin: '10px',
                                    padding: '4px',
                                    minHeight: '192px',
                                    backgroundColor: '#f9f9f9'
                                  }}
                                >
                                  {cabinetPages[pageIndex].cabinets.map((cabinet, index) => (
                                    <Draggable key={cabinet.id} draggableId={cabinet.id} index={index}>
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            padding: '8px',
                                            margin: '4px 0',
                                            background: 'lightgreen',
                                            borderRadius: '4px',
                                            ...provided.draggableProps.style
                                          }}
                                        >
                                          {cabinet.name}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </Stack>
                        ))}
                      </div>
                    </DragDropContext>
                  </Box>
                ) : (
                  <CenteredDiv>Selected a layout to edit or create a new one.</CenteredDiv>
                )}
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}
